
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.attachment {
  color: #101828;
  transition: background-color 0.2s ease-in-out;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 16px 0 0 0;
  cursor: pointer;
  &:first-child {
    margin: 0;
  }
  &:before {
    display: none;
  }
  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__file-data {
    flex: 1;
    padding: 5px 16px 5px 0;
  }
  &__file-preview {
    width: 40px;
    height: 40px;
    margin: 0 16px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(16, 24, 40, 0.05);
    border-radius: 12px;
  }
  &__file-icon {
    width: 20px;
  }
  &__file-image {
    width: 100%;
  }
  &__file-name, &__file-size {
    font-size: 12px;
    line-height: 16px;
  }
  &__file-size {
    color: rgba(181, 183, 192, 1);
  }
  &__file-remove {
    width: 16px;
    height: 18px;
    cursor: pointer;
    float: right;
    color: #999;
    transition: all 0.2s ease;
    &:hover {
      color: #444;
    }
  }
}
