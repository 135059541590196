
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.base-date {
  font-size: 12px;
  line-height: 16px;
}
